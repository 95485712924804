<template>
<div class="main">
  <div class="component-content">

    <TopMenu class="mb-12p">

      <template v-slot:page-name>新增货物</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 h-100">

      <div class="d-flex">
        <div class="flex-list-header"></div>
        <div class="flex-list hr w-100">
          <div style="width:15%;padding-left:24px;">名称</div>
          <div style="width:15%;">简称</div>
          <div style="width:8%;">规格</div>
          <div style="width:8%;">单位</div>
          <div style="width:8%;">包装</div>
          <div style="width:8%;">形态</div>
          <div style="width:8%;">型号</div>
          <div style="width:10%;">品牌</div>
          <div style="width:10%;">参考成本</div>
          <div style="width:10%;">默认单价</div>
        </div>
      </div>

      <div class="d-flex" v-for="(item, index) in datas" :key="item.index">
          <div class="flex-list-header">
            <div class="bg-38">{{ index + 1 }}</div>
            <div class="delete bg-red" @click="deleteRow(index)">-</div>
          </div>
          <div class="flex-list hr w-100">
            <div style="width:15%;padding-left:24px;">
              <input type="text" class="form-control p-0 bg-none" placeholder="名称" autoComplete="off" v-model="datas[index].name">
            </div>
            <div style="width:15%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="简称" autoComplete="off" v-model="datas[index].nickname">
            </div>
            <div style="width:8%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="datas[index].specification">
            </div>
            <div style="width:8%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="单位" autoComplete="off" v-model="datas[index].unit">
            </div>
            <div style="width:8%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="包装" autoComplete="off" v-model="datas[index].package">
            </div>
            <div style="width:8%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="形态" autoComplete="off" v-model="datas[index].status">
            </div>
            <div style="width:8%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="型号" autoComplete="off" v-model="datas[index].model">
            </div>
            <div style="width:10%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="品牌" autoComplete="off" v-model="datas[index].brand">
            </div>
            <div style="width:10%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0.00" autoComplete="off" v-model="datas[index].cost">
            </div>
            <div style="width:10%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0.00" autoComplete="off" v-model="datas[index].price">
            </div>
          </div>
      </div>
      <button class="btn btn-create p-0" @click="create()">+</button>
      
    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'ProductEdit',

  data() {
    return {
      loading: false,
      datas: [
        {
          id: null,
          name: null,
          nickname: null,
          specification: 25,
          unit: 'kg',
          package: '桶',
          status: '液体',
          model: null,
          brand: null,
          cost: null,
          price: null,
        }
      ],
      allowAttack: false,
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    if (this.$route.params.id) {
      this.updateData()
      console.log('修改')
    }
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
  },

  methods: {

    //更新订单
    updateData() {
      this.$axios.post('/api/product/get', {
        id: this.$route.params.id
      },{
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.datas = res.data
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    create() {
      this.datas.push({
        id: null,
        name: null,
        nickname: null,
        specification: 25,
        unit: 'kg',
        package: '桶',
        status: '液体',
        model: null,
        brand: null,
        cost: null,
        price: null,
      })
    },

    deleteRow(index) {
      if (this.datas.length > 1) {
        this.datas.splice(index, 1)
      }
    },

    destroy() {
      this.$router.push({name: 'ProductList'})
    },

    save() {
      let _this = this
      this.allowAttack = true

      try {

        this.datas.forEach(function(item) {
          if (item.name==null) throw '请填写货物名称'
          if (item.specification==null) throw '请填写货物规格'
          if (item.unit==null) throw '请填写货物单位'
          if (item.package==null) throw '请填写货物包装'
          if (item.status==null) throw '请填写货物状态'
        })

        _this.$axios.post('/api/product/save',
          {
            product: _this.datas
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          _this.allowAttack = false

          if (res.status == 200) {
            console.log(res.data)
            if (res.data.success) {
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
            }
            
          }
        })
        .catch(error => {
          _this.allowAttack = false
          console.error('error:', error)
        })
      } catch(error) {
        console.error(error)
        _this.allowAttack = false
        this.$toast.error(error)
      }
    }

  },

}
</script>

<style scoped>
  
.flex-list-header .delete {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flex-list-header:hover .delete {
  display: block;
}
.btn-create {
  width: 40px;
  height: 40px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  padding: 0;
  flex-shrink: 0;
  background-color: #004bff;
}

.vc-container.vc-is-dark {
  color: #fff;
  background-color: #383838;
  border: none;
}
  
</style>
